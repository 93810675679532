import { globalService as gs } from "./global.service";
import axios from 'axios';
import { uploadSelectors } from "../redux/selectors";

const countries = (method = "GET", params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/countries`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const states = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/states`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET"));
};

const newsletter = (params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/newsletter`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions("POST", params));
};

const timeZone = (method = "GET", params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/timezone`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const contact = (params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/contact`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions("POST", params));
};

// Image upload service
const deleteFile = (params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/delete-file`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions("DELETE", params));
};

const autocomplete = (params = null, item = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/${item}-autocomplete`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const categories = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/${gs.identity ? 'categories' : 'public-categories'}`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const skills = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/skills`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const questions = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/questions`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const platforms = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/platforms`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const notification = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/notifications`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const coreField = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/core-field-options`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const item = (params = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/item/${gs.identity ? 'index' : 'public-index'}`);
    url = (params !== null) ? gs.requestParams(url, params) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const coupons = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/coupons`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const settings = (method = "POST", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/settings`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const config = (params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/config`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};

const resubmitVerification = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/user/resubmit-verification`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

const message = (method = "GET", params = null, params2 = null) => {
    let url = gs.httpURL(`${gs.apiRoot}/default/message`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions(method, params));
};

// Image upload service 
const uploadFile = (files) => {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('file[]', f));
    return fetch(`${gs.apiRoot}/default/upload`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${gs.authToken()}` },
        body: formData
    })
        .then(
            response => {
                if (!response.ok) {
                    throw response
                }
                return response.json();
            });
};

const uploadWithoutProgress = (files) => {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('file[]', f));
    return fetch(`${gs.apiRoot}/default/upload`, {
        method: 'POST',
        headers: { 'Authorization': `Bearer ${gs.authToken()}` },
        body: formData
    })
        .then(
            response => {
                if (!response.ok) {
                    throw response
                }
                return response.json();
            });
};

const uploadAndProgress = (files, dispatch) => {
    const formData = new FormData();
    Array.from(files).forEach(f => formData.append('file[]', f));
    return axios.request({
        method: "post",
        url: `${gs.apiRoot}/default/upload`,
        headers: { 'Authorization': `Bearer ${gs.authToken()}` },
        data: formData,
        onUploadProgress: (p) => {
            let progress = calcProgressPercent(p);
            dispatch && dispatch(uploadSelectors.progress(progress));
        }
    }).then(
        response => {
            if (response.status !== 200) {
                throw response
            }
            return response.data;
        })
};

const calcProgressPercent = (event) => {
    let progress = Math.round(100 * event.loaded / event.total);
    return progress
};


const stream = (params2 = null) => {
    let url = gs.httpURL(`http://157.245.131.86:5080/rest/request?_path=LiveApp/rest/v2/broadcasts/845964548650598142186153`);
    url = (params2 !== null) ? gs.requestParams(url, params2) : url;
    return gs.request(url, gs.requestOptions("GET", null));
};


export const defaultService = {
    countries,
    states,
    timeZone,
    contact,
    autocomplete,
    uploadFile,
    deleteFile,
    uploadWithoutProgress,
    uploadAndProgress,
    calcProgressPercent,
    categories,
    skills,
    questions,
    platforms,
    coreField,
    item,
    newsletter,
    notification,
    coupons,
    settings,
    config, 
    resubmitVerification,
    stream,
    message
};
